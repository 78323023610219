export const T = {
  2024: {
    eon: {
      per: [
        100, 100, 100, 100, 99.9, 99.8, 99.4, 98.6, 97.1, 94.5, 90.3, 84.3,
        76.4, 67.0, 56.7, 46.0, 35.9, 26.9, 19.0,
      ],
      score: [
        80.9, 78.4, 76.0, 73.6, 71.1, 68.7, 66.3, 63.8, 61.4, 59.0, 56.5, 54.1,
        51.7, 49.3, 46.8, 44.4, 42.0, 39.5, 37.1,
      ],
    },
    chu: {
      per: [
        100, 100, 100, 99.9, 99.8, 99.5, 99.1, 98.3, 96.9, 95, 92.3, 88.7, 84.3,
        79.3, 73.3, 66.6, 59.7, 52.8, 46.2, 39.9,
      ],
      score: [
        97.5, 95.4, 93.3, 91.2, 89.1, 87.0, 84.8, 82.7, 80.6, 78.5, 76.4, 74.3,
        72.2, 70.1, 68.0, 65.9, 63.8, 61.7, 59.6, 57.5,
      ],
    },
  },
  2023: {
    eon: {
      per: [
        100, 99.9, 99.8, 99, 98, 97.3, 95.3, 92.3, 87.9, 82.3, 75.7, 67.8, 58.8,
        49.8, 40.7, 32.1, 24.5,
      ],
      score: [
        72.5, 70.4, 68.3, 66.2, 64.1, 62.0, 59.9, 57.8, 55.7, 53.6, 51.5, 49.3,
        47.2, 45.1, 43, 40.9, 38.8,
      ],
    },
    chu: {
      per: [
        100, 99.8, 99.6, 99.4, 99.3, 98, 97.7, 96, 94.3, 91.6, 88.1, 83.9, 78.8,
        73.1, 66.9, 60.5, 53.9, 47.3, 40.9, 34.8, 29.3,
      ],
      score: [
        94.8, 92.7, 90.7, 88.6, 86.5, 84.5, 82.4, 80.4, 78.3, 76.2, 74.1, 72.1,
        70, 68, 65.9, 63.8, 61.8, 59.7, 57.7, 55.6, 53.5,
      ],
    },
  },
};

export const NtoPerScore = (year, type, N) => {
  const M = type === "eon" ? 30 : 40;

  return [
    T[year.toString()][type]["per"][M - N],
    T[year.toString()][type]["score"][M - N],
  ];
};

export const NMin = (year, type) => {
  const M = type === "eon" ? 30 : 40;
  return M - T[year.toString()][type]["per"].length + 1;
};
