export const SchoolScore = (year, school) => {
  const data = {};
  switch (school) {
    case "SEOUL":
      data.verboseName = "서울대";
      switch (year) {
        case "2024":
          return {
            ...data,
            status: "OK",
            // LEET (전체 일반 특전) GPA (전체 일반 특전)
            mean: [58.14, 58.24, 56.91, 58.52, 58.53, 58.34],
            q4: [60, 60, 60, 60, 60, 60],
            q3: [59.12, 59.12, 58.98, 59.22, 59.22, 59.28],
            q2: [58.18, 58.18, 57.06, 58.74, 58.74, 58.38],
            q1: [57.5, 57.56, 55.59, 57.84, 57.84, 57.84],
            q0: [0, 18.92, 0, 0, 51, 0], //
          };
        case "2023":
          return {
            ...data,
            status: "OK",
            mean: [58.33, 58.44, 56.84, 58.25, 58.29, 57.73],
            q4: [60, 60, 60, 60, 60, 60],
            q3: [59.1, 59.22, 58.83, 58.92, 58.92, 58.56],
            q2: [58.54, 58.54, 56.82, 58.32, 58.32, 57.72],
            q1: [57.82, 57.9, 56.32, 57.72, 57.72, 57.6],
            q0: [0, 16.43, 0, 0, 51, 0], //
          };
        default:
          return {
            ...data,
            status: "TBU",
          };
      }
    case "YONSEI":
      data.verboseName = "연세대";
      switch (year) {
        case "2024":
          return {
            ...data,
            status: "OK",
            mean: [140.22, 140.42, 137.63, 148.02, 148.06, 147.57],
            q4: [150, 150, 150, 150, 150, 150],
            q3: [141.3, 141.3, 138.5, 149, 149, 148.3],
            q2: [140.4, 140.4, 137.3, 148.5, 148.5, 147.2],
            q1: [139.3, 139.3, 136.5, 147.45, 147.5, 146.9],
            q0: [0, 95, 0, 0, 135, 0],
          };
        case "2023":
          return {
            ...data,
            status: "OK",
            mean: [140.27, 140.47, 137.7, 147.02, 147.0, 147.2],
            q4: [150, 150, 150, 150, 150, 150],
            q3: [141.75, 142.2, 138.9, 148, 148, 148],
            q2: [140.4, 138.1, 140.4, 147.2, 147.6, 147.2],
            q1: [138.9, 138.9, 136.5, 146.1, 146.05, 147],
            q0: [0, 81.5, 0, 0, 135, 0],
          };
        default:
          return {
            ...data,
            status: "TBU",
          };
      }
    case "KOREA":
      data.verboseName = "고려대";
      switch (year) {
        case "2024":
          return {
            ...data,
            status: "OK",
            leet: "SPLIT",
            // 언어 (전체 일반 특전) 추리 (전체 일반 특전) GPA (전체 일반 특전)
            mean: ["-", 78.98, 78.78, "-", 118.77, 118.62, "-", 148.52, 148.16], //TODO: Q0 만들기
            q4: ["-", 80, 80, "-", 120, 120, "-", 150, 150],
            q3: ["-", 79.42, 78.9, "-", 119.49, 119.07, "-", 149.08, 148.68],
            q2: ["-", 78.9, 78.9, "-", 119.07, 118.5, "-", 148.64, 147.8],
            q1: ["-", 78.06, 78.06, "-", 118.5, 117.69, "-", 148.08, 147.56],
            q0: [0, 31.96, 0, 0, 86.6, 0, 0, 121.4, 0],
          };
        case "2023":
          return {
            ...data,
            status: "OK",
            leet: "SPLIT",
            // 언어 (전체 일반 특전) 추리 (전체 일반 특전) GPA (전체 일반 특전)
            mean: ["-", 78.83, 76.82, "-", 118.48, 115.55, "-", 197.37, 197.25], //TODO: Q0 만들기
            q4: ["-", 80, 80, "-", 120, 120, "-", 200, 200],
            q3: ["-", 79.4, 78.4, "-", 119.4, 117.98, "-", 198.32, 198.18],
            q2: ["-", 79, 77.33, "-", 118.2, 117.3, "-", 197.62, 197.9],
            q1: ["-", 78.4, 75.98, "-", 118.2, 116.4, "-", 196.57, 196.34],
            q0: [0, 36.96, 0, 0, 76.6, 0, 0, 189.5, 0],
          };
        default:
          return {
            ...data,
            status: "TBU",
          };
      }
    case "SKKU":
      data.verboseName = "성균관대";
      switch (year) {
        case "2024":
          return {
            ...data,
            status: "OK",
            // LEET (전체 일반 특전) GPA (전체 일반 특전)
            mean: [25.08, 25.14, 24.35, 29.21, 29.24, 28.65],
            q4: [30, 30, 30, 30, 30, 30],
            q3: [25.59, 25.6, 25.64, 29.56, 29.57, 29.43],
            q2: [25.07, 25.1, 24.12, 29.32, 29.33, 28.34],
            q1: [24.5, 24.53, 23.12, 28.92, 28.99, 28.23],
            q0: [0, 18.6, 0, 0, 27.3, 0],
          };
        case "2023":
          return {
            ...data,
            status: "OK",
            // LEET (전체 일반 특전) GPA (전체 일반 특전)
            mean: [25.16, 25.23, 24.32, 29.06, 29.05, 29.21],
            q4: [30, 30, 30, 30, 30, 30],
            q3: [25.55, 25.83, 24.89, 29.39, 29.35, 29.39],
            q2: [25.23, 25.23, 24.29, 29.14, 29.12, 29.33],
            q1: [24.6, 24.62, 23.67, 28.83, 28.81, 29.05],
            q0: [0, 18.3, 0, 0, 27.3, 0],
          };
        default:
          return {
            ...data,
            status: "TBU",
          };
      }

    case "HYU":
      data.verboseName = "한양대";
      switch (year) {
        case "2024":
          return {
            ...data,
            status: "OK",
            // LEET (전체 일반 특전) GPA (전체 일반 특전)
            mean: ["-", 29.924, 24.371, "-", 18.818, 18.526],
            q4: ["-", 40, 40, "-", 20, 20],
            q3: ["-", 30.808, 27.492, "-", 19.405, 19.58],
            q2: ["-", 29.712, 25.698, "-", 18.95, 18.67],
            q1: ["-", 29.093, 23.478, "-", 18.495, 18.075],
            q0: ["-", 16.15, 0, "-", 14.75, 0],
          };
        case "2023":
          return {
            ...data,
            status: "OK",
            // LEET (전체 일반 특전) GPA (전체 일반 특전)
            mean: ["-", 30.803, 28.801, "-", 18.529, 18.464],
            q4: ["-", 40, 40, "-", 20, 20],
            q3: ["-", 31.622, 30.578, "-", 19.02, 19.02],
            q2: ["-", 30.773, 29.889, "-", 18.705, 18.39],
            q1: ["-", 29.959, 27.637, "-", 18.25, 18.25],
            q0: ["-", 15.59, 0, "-", 14.75, 0],
          };
        default:
          return {
            ...data,
            status: "TBU",
          };
      }

    case "EWHA":
      data.verboseName = "이화여대";
      switch (year) {
        case "2024":
          return {
            ...data,
            status: "OK",
            // LEET (전체 일반 특전) GPA (전체 일반 특전)
            mean: ["-", 61.97, 59.49, "-", 37.62, 37.58],
            q4: ["-", 70, 70, "-", 40, 40],
            q3: ["-", 63.31, 64.99, "-", 38.76, 39.44],
            q2: ["-", 61.84, 60.09, "-", 38.05, 37.95],
            q1: ["-", 60.37, 56.73, "-", 36.84, 37.58],
            q0: ["-", 36.22, 0, "-", 34, 0],
          };
        case "2023":
          return {
            ...data,
            status: "OK",
            // LEET (전체 일반 특전) GPA (전체 일반 특전)
            mean: ["-", 61.76, 58.71, "-", 37.7, 37.91],
            q4: ["-", 70, 70, "-", 40, 40],
            q3: ["-", 62.47, 60.93, "-", 38.88, 39.11],
            q2: ["-", 61.0, 57.99, "-", 37.95, 38.14],
            q1: ["-", 60.86, 57.99, "-", 36.84, 37.95],
            q0: ["-", 36.22, 0, "-", 34, 0],
          };
        default:
          return {
            ...data,
            status: "TBU",
          };
      }

    default:
      return {
        state: "TBU",
      };
  }
};
